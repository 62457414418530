<template>
  <v-container>
    <h1>{{ title }}</h1>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field
          v-model="estimate.name"
          required
          label="Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field
          v-model="estimate.email"
          required
          label="Email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field
          v-model="estimate.phone"
          required
          label="Phone"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field
          v-model="estimate.address"
          required
          label="Address"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field v-model="estimate.city" label="City"></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="6" sm="12">
        <v-text-field v-model="estimate.zipcode" label="Zipcode"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
        <v-text-field
          v-model="estimate.projectType"
          label="Project Type"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Created Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="12" sm="12">
        <v-text-field v-model="estimate.message" label="Message"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn outlined class="button" @click="onCancel()">
        <v-icon left>mdi-cancel</v-icon>Cancel
      </v-btn>
      <v-btn outlined class="button" @click="onSave()">
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import router from "@/router";
import store from "@/store";

export default {

  created() {
    this.get();
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  data: (vm) => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    title: "Edit Estimate",
    estimate: {
      id: 0,
      address: "",
      city: "",
      email: "",
      estimateDate: "",
      name: "",
      message: "",
      phone: "",
      projectType: "",
      zipcode: "",
    },
  }),

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    get() {
      if (this.$route.params.id > 0) {
        store.commit("loading");
        const url = "estimates/" + this.$route.params.id;
        apiService.get(url, this.getSuccess, this.getError);
      }
    },

    getSuccess(result) {
      this.estimate = result.data
    },

    getError(error) {
      console.log("Error getting estimate: ", error)
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onCancel() {
      router.go(-1);
    },

    onSave() {
      console.log("Content: ", this.estimate);
      const url = "estimates/" + this.estimate.id
      apiService.put(url,this.estimate, this.saveSuccess, this.saveError)
    },

    saveSuccess() {
      const notification = {
        title: "Estimate Saved",
        status: "success",
        messages: ["Estimate saved successfully"],
      };
      store.commit("setNotification", notification);
      router.go(-1)
    },

    saveError(error) {
      console.log("Error saving estimate: ", error)
    }
  },
  router,
  store,
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-right: 20px;
}
</style>