var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Estimates")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.estimates,"item-key":"id","show-expand":"","single-expand":true,"footer-props":{
          'items-per-page-options': _vm.rowsPerPageItems,
        }},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onEdit(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1)],1)]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
return [_c('div',[(_vm.isAdministrator)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expand-row"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12"}},[_c('v-row',[_c('strong',[_vm._v("Address:")])]),_c('v-row',[_vm._v(" "+_vm._s(item.address)+" ")]),_c('v-row',[_vm._v(_vm._s(item.city)+" ")]),_c('v-row',[_vm._v(_vm._s(item.zipcode)+" ")])],1),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12"}},[_c('v-row',[_c('strong',[_vm._v(" Project Type:")])]),(item.projectType.length > 0)?_c('v-row',[_vm._v(" "+_vm._s(item.projectType)+" ")]):_vm._e(),(item.projectType.length == 0)?_c('v-row',[_vm._v(" (No project type given) ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"8","sm":"12"}},[_c('v-row',[_c('strong',[_vm._v(" Message:")])]),(item.message.length > 0)?_c('v-row',[_vm._v(" "+_vm._s(item.message)+" ")]):_vm._e(),(item.message.length == 0)?_c('v-row',[_vm._v(" (No message was given) ")]):_vm._e()],1)],1)],1)])]}}])})],1)],1),_c('div',[_c('status',{ref:"alert",attrs:{"successMessage":"Estimate deleted"},on:{"closed":_vm.onStatusAlertClosed}})],1),_c('div',[_c('v-snackbar',{attrs:{"timeout":-1,"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"button",attrs:{"text":""},on:{"click":_vm.onYes}},'v-btn',attrs,false),[_vm._v(" Yes ")]),_c('v-btn',_vm._b({staticClass:"button",attrs:{"text":""},on:{"click":_vm.onNo}},'v-btn',attrs,false),[_vm._v(" No ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Delete the selected Estimate ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }