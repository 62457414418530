<template>
  <v-container>
    <h1>Estimates</h1>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items-per-page="itemsPerPage"
          :items="estimates"
          item-key="id"
          show-expand
          :single-expand="true"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
          }"
        >
          <template v-slot:item.edit="{ item }">
            <div>
              <v-btn outlined @click="onEdit(item.id)">
                <v-icon left>mdi-pencil</v-icon>Edit
              </v-btn>
            </div>
          </template>
          <template v-slot:item.delete="{ item }">
            <div>
              <v-btn outlined @click="onDelete(item.id)" v-if="isAdministrator">
                <v-icon left>mdi-delete</v-icon>Delete
              </v-btn>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="expand-row">
                <v-row>
                  <v-col cols="12" xl="2" lg="2" md="2" sm="12">
                    <v-row>
                      <strong>Address:</strong>
                    </v-row>
                    <v-row>
                      {{ item.address }}
                    </v-row>
                    <v-row>{{ item.city }} </v-row>
                    <v-row>{{ item.zipcode }} </v-row>
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="2" sm="12">
                    <v-row>
                      <strong> Project Type:</strong>
                    </v-row>
                    <v-row v-if="item.projectType.length > 0">
                      {{ item.projectType }}
                    </v-row>
                    <v-row v-if="item.projectType.length == 0">
                      (No project type given)
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="8" lg="8" md="8" sm="12">
                    <v-row>
                      <strong> Message:</strong>
                    </v-row>
                    <v-row v-if="item.message.length > 0">
                      {{ item.message }}
                    </v-row>
                    <v-row v-if="item.message.length == 0">
                      (No message was given)
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div>
      <status
        ref="alert"
        successMessage="Estimate deleted"
        @closed="onStatusAlertClosed"
      ></status>
    </div>
    <div>
      <v-snackbar v-model="snackbar" :timeout="-1" centered>
        Delete the selected Estimate
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="onYes" class="button"> Yes </v-btn>
          <v-btn text v-bind="attrs" @click="onNo" class="button"> No </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import router from "@/router";
import status from "@/components/controls/Status";
import store from "@/store"

export default {
  name: "Estimates",

  components: {
    status,
  },

  created() {
    this.fetch();
  },

  data: () => ({
    estimates: [],
    headers: [
      {
        text: "Name",
        align: "start",
        groupable: false,
        sortable: true,
        value: "name",
      },
      {
        text: "email",
        align: "start",
        groupable: false,
        sortable: true,
        value: "email",
      },
      {
        text: "Phone",
        align: "start",
        groupable: false,
        sortable: true,
        value: "phone",
      },
      {
        text: "Date",
        align: "start",
        groupable: false,
        sortable: true,
        value: "estimateDate",
      },
      {
        text: "",
        align: "center",
        groupable: false,
        sortable: false,
        value: "edit",
        width: 60,
      },
      {
        text: "",
        align: "center",
        groupable: false,
        sortable: false,
        value: "delete",
        width: 60,
      },
    ],
    deleteId: -1,
    itemsPerPage: 10,
    isAdministrator: store.getters.isAdministrator,
    rowsPerPageItems: [10, 25, 50, 100],
    snackbar: false,
  }),

  methods: {
    fetch() {
      apiService.get("estimates", this.getSuccess, this.getError)
    },

    getSuccess(result){
      this.estimates = result.data
    },

    getError(error) {
      console.log("Error getting estimates: ", error)
    },

    onDelete(id) {
      console.log("onDelete() ", id)
      this.deleteId = id
      this.snackbar = true
    },

    onEdit(id) {
      console.log("onEdit() ", id)
      router.push("estimate/" + id)
    },

    onNo() {
      this.snackbar = false
    },

    onYes() {
      this.snackbar = false
      if (this.deleteId != -1) {
        const url = "estimates/" + this.deleteId
        apiService.delete(url, this.deleteSuccess, this.deleteError)
      }
    },

    deleteSuccess() {
      const notification = {
        title: "Delete Estimate",
        status: "success",
        messages: ["Estimate Deleted"],
      };
      store.commit("setNotification", notification);
      this.fetch()
    },

    deleteError(error){
      console.log("Error deleting estimate: ", error)
    },

    onStatusAlertClosed() {},
  },

  router,
};
</script>

<style lang="scss" scoped>
.button {
  margin-left: 20px;
}
.expand-row {
  padding: 1em;
}
</style>
